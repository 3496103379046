/* eslint-disable */

// ---------------------------------------------------------
// !!! THIS FILE WAS GENERATED BY GRAPHQL-CODE-GENERATOR !!!
// !!!        DO NOT MODIFY THIS FILE BY YOURSELF        !!!
// ---------------------------------------------------------

import type { Auth } from '@pepper/common';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  AccessPermissions: Auth.AccessPermissions;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  AccessRoles: Auth.AccessRoles;
  /** A number, a stringified number, or a string. For allowing both cursor/offset pagination. */
  Cursor: string | number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string | Date;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: Record<string, any>;
  /** A field whose value conforms with the standard mongodb object ID as described here: https://docs.mongodb.com/manual/reference/method/ObjectId/#ObjectId. Example: 5e5677d71bdc2ae76344968c */
  ObjectId: string;
  /** Range custom scalar type */
  Range: [number, number];
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: any;
};

/** User's roles and mapped permissions matrix */
export type Access = {
  __typename?: 'Access';
  /** True for SSO users, false for Password users - used to decide if we show PII */
  isSSO?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  permissions: Scalars['AccessPermissions'];
  roles: Scalars['AccessRoles'];
};

export type Action = {
  __typename?: 'Action';
  /** Unique action ID */
  _id: Scalars['ObjectId'];
  content: ActionContentBase;
  /**
   * This is used to link actions to multiple entities for the purposes of related histories.
   * For eg. a vendor onboarding's entity will be 'Vendor', but it will also link to 'Partner', 'Stall', and 'Facility'.
   */
  links: Array<EntityLink>;
  metadata: ActionMetadata;
  state: ActionState;
  /** Combination of entityType + entityId refers to a unique document in some other collection. */
  subject: EntityLink;
  /** when action was (supposed to be) executed at */
  timestamp: Scalars['DateTime'];
  type: ActionType;
};

export type ActionConnection = Connection & {
  __typename?: 'ActionConnection';
  /** Use this if you want to do cursor based pagination, otherwise just use `nodes` directly. */
  edges: Array<ActionEdge>;
  /** Use this directly if you don't want cursor based pagination, otherwise use `edges`. */
  nodes: Array<Action>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ActionContentBase = {
  actionType: ActionType;
  /**
   * Use this to document the change request.
   * For eg. user input text for Sales/Admin, jira tickets or slack discussions for migrations.
   */
  comment?: Maybe<Scalars['String']>;
};

export type ActionEdge = {
  __typename?: 'ActionEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['Cursor'];
  node: Action;
};

export type ActionMetadata = {
  __typename?: 'ActionMetadata';
  /** UserId of who initiated the change request. When the change is made by an automation, use the PEPPER_TECH_USER_ID in those scenarios. */
  createdBy: Scalars['ObjectId'];
  createdByUser?: Maybe<User>;
  createdVia: Scalars['String'];
  /** UserId of who initiated the change request. When the change is made by an automation, use the PEPPER_TECH_USER_ID in those scenarios. */
  updatedBy: Scalars['ObjectId'];
  updatedByUser?: Maybe<User>;
  updatedVia: Scalars['String'];
};

export type ActionSort = {
  sortBy?: InputMaybe<ActionSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

/** Action sort by options */
export enum ActionSortBy {
  ActionType = 'ActionType',
  CreatedAt = 'CreatedAt',
  SubjectType = 'SubjectType',
  Timestamp = 'Timestamp',
  UpdatedAt = 'UpdatedAt'
}

export type ActionState = {
  __typename?: 'ActionState';
  /** Some context on actions' status like error messages. */
  message?: Maybe<Scalars['String']>;
  status: ActionStatus;
};

export enum ActionStatus {
  Cancelled = 'Cancelled',
  Draft = 'Draft',
  Errored = 'Errored',
  Executed = 'Executed',
  Scheduled = 'Scheduled'
}

export enum ActionType {
  StallOffboarding = 'StallOffboarding',
  StallOnboarding = 'StallOnboarding'
}

export type ActionsFilter = {
  date?: InputMaybe<DateFilterInput>;
  links?: InputMaybe<Array<EntityInput>>;
  statuses?: InputMaybe<Array<ActionStatus>>;
  subjects?: InputMaybe<Array<EntityInput>>;
  types?: InputMaybe<Array<ActionType>>;
};

export type Announcement = TimeStamps & {
  __typename?: 'Announcement';
  createdAt: Scalars['DateTime'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Data to assign leads of a single brand */
export type AssignLeadsInput = {
  assigneeId: Scalars['ObjectId'];
  brandId: Scalars['ObjectId'];
  note?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['ObjectId']>;
  /** New Partner is created with this name and assigned to this brand */
  partnerName?: InputMaybe<Scalars['String']>;
};

export type AuthenticateParamsInput = {
  access_token?: InputMaybe<Scalars['String']>;
  access_token_secret?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserInput>;
};

export type Brand = TimeStamps & {
  __typename?: 'Brand';
  _id: Scalars['ObjectId'];
  /** Platform account manager */
  accManagers?: Maybe<Array<BrandAccountManager>>;
  /**
   * Brand account Managers list
   * @deprecated use `accManagers` instead
   */
  accountManagers: Array<Scalars['String']>;
  assignee?: Maybe<User>;
  /** Brand's assignee, old signed/lost leads could have been assigned to different users. */
  assigneeId?: Maybe<Scalars['ObjectId']>;
  category?: Maybe<BrandCategory>;
  country: Country;
  countryId: Scalars['ObjectId'];
  createdAt: Scalars['DateTime'];
  flag?: Maybe<BrandFlag>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isManual?: Maybe<Scalars['Boolean']>;
  keyAccountType?: Maybe<Scalars['String']>;
  /** @deprecated Can do this on the client side */
  latestActivity?: Maybe<BrandNote>;
  leads: Array<Lead>;
  mainCuisine?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: Maybe<Array<BrandNote>>;
  partner?: Maybe<Partner>;
  partnerId?: Maybe<Scalars['ObjectId']>;
  /** if any of the brand's lead is any one of pipeline stages */
  pipelineStatus?: Maybe<PipelineStatus>;
  predictions?: Maybe<BrandPredictions>;
  recommendation?: Maybe<Recommendation>;
  /** Same brand present in other countries (includes self) */
  regionalBrands: BrandsFieldConnection;
  stats?: Maybe<BrandStats>;
  status: BrandStatus;
  updatedAt: Scalars['DateTime'];
};


export type BrandLeadsArgs = {
  includeNew?: InputMaybe<Scalars['Boolean']>;
};

export type BrandAccountManager = {
  __typename?: 'BrandAccountManager';
  /** Brand account Managers email */
  email: Scalars['String'];
  /** Brand account Managers name */
  name: Scalars['String'];
};

export enum BrandCategory {
  Bronze = 'Bronze',
  Gold = 'Gold',
  Na = 'NA',
  Silver = 'Silver'
}

export type BrandConnection = Connection & {
  __typename?: 'BrandConnection';
  /** Use this if you want to do cursor based pagination, otherwise just use `nodes` directly. */
  edges: Array<BrandEdge>;
  /** Use this directly if you don't want cursor based pagination, otherwise use `edges`. */
  nodes: Array<Brand>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type BrandConnectionFilter = {
  accountManagers?: InputMaybe<Array<Scalars['String']>>;
  assigneeIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Min Max of AOV */
  averageOrderValueMinMax?: InputMaybe<Scalars['Range']>;
  brandStatusHistory?: InputMaybe<Array<BrandStatus>>;
  categories?: InputMaybe<Array<BrandCategory>>;
  countryId?: InputMaybe<Scalars['ObjectId']>;
  /** Has priority over `eligible` and `facilityIds` filter */
  eligibleFacilityIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  excludedLeadStatuses?: InputMaybe<Array<LeadStatus>>;
  facilityIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Hide brands with archived */
  hideArchived?: InputMaybe<Scalars['Boolean']>;
  /** Filters out NEW Brands that were imported before this date */
  importedAt?: InputMaybe<Scalars['Date']>;
  /** Also include brands that are New and have never been assigned to anyone */
  includeCurrentAndNew?: InputMaybe<Scalars['Boolean']>;
  isAmSupportNeeded?: InputMaybe<Scalars['Boolean']>;
  /** Has at least 1 eligible lead */
  isEligible?: InputMaybe<Scalars['Boolean']>;
  keyAccountTypes?: InputMaybe<Array<Scalars['String']>>;
  leadAssigneeIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  leadStatuses?: InputMaybe<Array<LeadStatus>>;
  mainCuisines?: InputMaybe<Array<Scalars['String']>>;
  /** Exact match with the `name */
  name?: InputMaybe<Scalars['String']>;
  /** Min Max of no. of branches */
  noOfBranchesMinMax?: InputMaybe<Scalars['Range']>;
  /** Min Max of order per branch */
  ordersPerBranchMinMax?: InputMaybe<Scalars['Range']>;
  partnerIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  pipelineStatuses?: InputMaybe<Array<PipelineStatus>>;
  /** Partial match of brand name */
  search?: InputMaybe<Scalars['String']>;
};

export type BrandEdge = {
  __typename?: 'BrandEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['Cursor'];
  node: Brand;
};

export type BrandFieldFilter = {
  countryId?: InputMaybe<Scalars['ObjectId']>;
};

export enum BrandFlag {
  AmSupportNeeded = 'AmSupportNeeded',
  AmSupportResolved = 'AmSupportResolved',
  None = 'None'
}

export type BrandNote = TimeStamps & {
  __typename?: 'BrandNote';
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ObjectId'];
  createdByUser: User;
  flag?: Maybe<BrandFlag>;
  updatedAt: Scalars['DateTime'];
};

/** Create Brand Note Input */
export type BrandNoteInput = {
  comment: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  flag?: InputMaybe<BrandFlag>;
};

export type BrandPredictions = {
  __typename?: 'BrandPredictions';
  averageBrandScore?: Maybe<Scalars['Float']>;
  maximumBrandScore?: Maybe<Scalars['Float']>;
  /** Month (first day of the month) for which scores were generated. Could be older than updatedAt if brand became inactive and has no recent predictions */
  predictionMonth?: Maybe<Scalars['DateTime']>;
  /** Date when the average scores was last calculated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BrandStats = {
  __typename?: 'BrandStats';
  /** AOV in local currency */
  averageOrderValue?: Maybe<Scalars['Float']>;
  chainSize?: Maybe<Scalars['Float']>;
  conversionRate?: Maybe<Scalars['Float']>;
  /** GBV in local currency */
  grossBookingValueLocal?: Maybe<Scalars['Float']>;
  noOfBranches?: Maybe<Scalars['Float']>;
  ordersPerBranch?: Maybe<Scalars['Float']>;
};

export type BrandStatsInfo = {
  __typename?: 'BrandStatsInfo';
  /** Brand account Managers list */
  accManagers: Array<BrandAccountManager>;
  /**
   * Brand account Managers list
   * @deprecated use `accManagers` instead
   */
  accountManagers: Array<Scalars['String']>;
  /** Avg Order value max value */
  averageOrderValueMax: Scalars['Float'];
  /** Avg Order value min value */
  averageOrderValueMin: Scalars['Float'];
  /** Brand cuisines list */
  mainCuisines: Array<Scalars['String']>;
  /** no of branches max value */
  noOfBranchesMax: Scalars['Float'];
  /** no of branches min value */
  noOfBranchesMin: Scalars['Float'];
  /** orders per branch max value */
  ordersPerBranchMax: Scalars['Float'];
  /** Orders per branch min value */
  ordersPerBranchMin: Scalars['Float'];
};

export type BrandStatsMinMax = {
  __typename?: 'BrandStatsMinMax';
  /** Avg Order value max value */
  averageOrderValueMax: Scalars['Float'];
  /** Avg Order value min value */
  averageOrderValueMin: Scalars['Float'];
  /** no of branches max value */
  noOfBranchesMax: Scalars['Float'];
  /** no of branches min value */
  noOfBranchesMin: Scalars['Float'];
  /** orders per branch max value */
  ordersPerBranchMax: Scalars['Float'];
  /** Orders per branch min value */
  ordersPerBranchMin: Scalars['Float'];
};

export enum BrandStatus {
  Approached = 'Approached',
  Churned = 'Churned',
  InPipeline = 'InPipeline',
  Live = 'Live',
  New = 'New'
}

export type BrandsFieldConnection = Connection & {
  __typename?: 'BrandsFieldConnection';
  /** Best brand currently takes the brand with the max orders per branch */
  bestBrand?: Maybe<Brand>;
  /** Use this if you want to do cursor based pagination, otherwise just use `nodes` directly. */
  edges: Array<BrandEdge>;
  /** Use this directly if you don't want cursor based pagination, otherwise use `edges`. */
  nodes: Array<Brand>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
  /** All brands total stats */
  totalStats: BrandStats;
};

export type Build = {
  __typename?: 'Build';
  buildId: Scalars['String'];
};

/** Multi brand bulk assignment input */
export type BulkAssignInput = {
  assigneeId: Scalars['ObjectId'];
  brandIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type Channel = {
  __typename?: 'Channel';
  type?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type Charges = {
  __typename?: 'Charges';
  discount_fee?: Maybe<Scalars['Float']>;
  joker_fee?: Maybe<Scalars['Float']>;
  payment_fee?: Maybe<Scalars['Float']>;
  service_fee?: Maybe<Scalars['Float']>;
};

export type Commission = {
  __typename?: 'Commission';
  amount?: Maybe<Scalars['Float']>;
  commissionable_value?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

export type CommonLeadUpdateFields = {
  brandId: Scalars['ObjectId'];
  note?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['ObjectId']>;
  /** New Partner is created with this name and assigned to this brand */
  partnerName?: InputMaybe<Scalars['String']>;
};

export type Connection = {
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export enum ContactType {
  Grow = 'Grow',
  Ops = 'Ops',
  Sales = 'Sales'
}

export type CookingAppDisplaySettings = {
  __typename?: 'CookingAppDisplaySettings';
  dispatchingTimeSec?: Maybe<Scalars['Int']>;
  highlightField?: Maybe<DisplayHighlightField>;
  statusView?: Maybe<StatusView>;
};

export type CookingAppDisplaySettingsInput = {
  dispatchingTimeSec?: InputMaybe<Scalars['Int']>;
  highlightField?: InputMaybe<DisplayHighlightField>;
  statusView?: InputMaybe<StatusView>;
};

export type CookingMetrics = {
  __typename?: 'CookingMetrics';
  avgCookingTime?: Maybe<Scalars['Float']>;
  avgPreparationTime?: Maybe<Scalars['Float']>;
  readyOrdersCount?: Maybe<Scalars['Float']>;
  totalVendorsDelayedOrdersCount?: Maybe<Scalars['Float']>;
  vendorDelayStats: VendorDelayStats;
};

export type CookingStaff = {
  __typename?: 'CookingStaff';
  _id: Scalars['ID'];
  facility: Facility;
  facilityId: Scalars['ObjectId'];
  staffCount?: Maybe<Scalars['Float']>;
  vendor: Scalars['String'];
};

export type Country = TimeStamps & {
  __typename?: 'Country';
  _id: Scalars['ID'];
  allowOrderExport?: Maybe<Scalars['Boolean']>;
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  defaultColumns?: Maybe<Array<CustomColumns>>;
  facilityType: FacilityType;
  globalEntityIds: Array<Scalars['String']>;
  /** @deprecated use locales field */
  locale: Scalars['String'];
  locales?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  salesAppSettings?: Maybe<SalesAppSettings>;
  timezone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CountryConnection = Connection & {
  __typename?: 'CountryConnection';
  /** Use this if you want to do cursor based pagination, otherwise just use `nodes` directly. */
  edges: Array<CountryEdge>;
  /** Use this directly if you don't want cursor based pagination, otherwise use `edges`. */
  nodes: Array<Country>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CountryConnectionFilter = {
  facilityType?: InputMaybe<FacilityType>;
  facilityTypes?: InputMaybe<Array<FacilityType>>;
};

export type CountryEdge = {
  __typename?: 'CountryEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['Cursor'];
  node: Country;
};

export type CountryForm = {
  allowOrderExport?: InputMaybe<Scalars['Boolean']>;
  countryCode: Scalars['String'];
  defaultColumns: Array<CustomColumnsInput>;
  facilityType: FacilityType;
  globalEntityIds: Array<Scalars['String']>;
  locales: Array<Scalars['String']>;
  name: Scalars['String'];
  salesAppSettings: SalesAppSettingsInput;
  timezone: Scalars['String'];
};

/** Create Brand input */
export type CreateBrandInput = {
  assigneeId?: InputMaybe<Scalars['ObjectId']>;
  countryId: Scalars['ObjectId'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partnerId?: InputMaybe<Scalars['ObjectId']>;
  /** New Partner is created with this name and assigned to this brand */
  partnerName?: InputMaybe<Scalars['String']>;
};

export type CreateCookingStaffInput = {
  facilityId: Scalars['ObjectId'];
  staffCount: Scalars['Float'];
  vendor: Scalars['String'];
};

/** Create facility input */
export type CreateFacilityInput = {
  countryId: Scalars['ObjectId'];
  location: FacilityLocationInput;
  name: Scalars['String'];
  numberOfStalls: Scalars['Int'];
  timezone: Scalars['String'];
};

export type CreatePartnerInput = {
  facilityType: FacilityType;
  name: Scalars['String'];
};

export type CreatePasswordUserInput = {
  email: Scalars['String'];
  facilityId: Scalars['ObjectId'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  partnerId?: InputMaybe<Scalars['ObjectId']>;
  role: UserRole;
};

export type CreateUserInput = {
  countries?: InputMaybe<Array<Scalars['ID']>>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  kitchen?: InputMaybe<Scalars['ID']>;
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  roles: Array<Roles>;
  username?: InputMaybe<Scalars['String']>;
};

export type CreateUserResult = {
  __typename?: 'CreateUserResult';
  loginResult?: Maybe<LoginResult>;
  userId?: Maybe<Scalars['ID']>;
};

export type CreateVendorInput = {
  globalEntityId: Scalars['String'];
  label: Scalars['String'];
  settings: VendorSettingsInput;
  stallId: Scalars['ObjectId'];
  vendorId: Scalars['String'];
};

export type CustomColumns = {
  __typename?: 'CustomColumns';
  display?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CustomColumnsInput = {
  display?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Customer = {
  __typename?: 'Customer';
  channel?: Maybe<Channel>;
  customer_id: Scalars['String'];
  payment?: Maybe<Payment>;
  profile?: Maybe<Profile>;
};

export type DateFilterInput = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type DefaultSort = {
  sortBy?: InputMaybe<DefaultSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

/** Default sort by options */
export enum DefaultSortBy {
  CreatedAt = 'CreatedAt',
  Id = 'Id',
  Name = 'Name',
  UpdatedAt = 'UpdatedAt'
}

export type Delivery = {
  __typename?: 'Delivery';
  delivery_fee?: Maybe<Scalars['Float']>;
  location?: Maybe<Location>;
  provider?: Maybe<Scalars['String']>;
  tax?: Maybe<Tax>;
  tip?: Maybe<Scalars['Float']>;
};

export type DetachVendorInput = {
  comment?: InputMaybe<Scalars['String']>;
  globalEntityId: Scalars['String'];
  reasons: Array<OffboardingReason>;
  vendorId: Scalars['String'];
};

export enum DisplayHighlightField {
  OrderId = 'ORDER_ID',
  ShortCode = 'SHORT_CODE'
}

export type EmailRecord = {
  __typename?: 'EmailRecord';
  address: Scalars['String'];
  verified: Scalars['Boolean'];
};

export type Entity = Action | Brand | Country | Facility | Partner | Stall | User | VendorModule;

export type EntityInput = {
  /** Might be an ObjectId format string or "Geid;VendorId" string (when type is Vendor) */
  id: Scalars['ID'];
  type: EntityType;
};

export type EntityLink = {
  __typename?: 'EntityLink';
  entity: Entity;
  /** Might be an ObjectId format string or "Geid;VendorId" string (when type is Vendor) */
  id: Scalars['ID'];
  type: EntityType;
};

export enum EntityType {
  Action = 'Action',
  Brand = 'Brand',
  Country = 'Country',
  Facility = 'Facility',
  Partner = 'Partner',
  Stall = 'Stall',
  User = 'User',
  Vendor = 'Vendor'
}

/** Vendor events Data */
export type Event = TimeStamps & {
  __typename?: 'Event';
  /** Additional comment */
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** Reasons for offboarding */
  reasons?: Maybe<Array<OffboardingReason>>;
  stall: Stall;
  stallId: Scalars['ObjectId'];
  type: EventType;
  updatedAt: Scalars['DateTime'];
};

export enum EventType {
  OffBoarding = 'OffBoarding',
  OnBoarding = 'OnBoarding'
}

export type Facility = TimeStamps & {
  __typename?: 'Facility';
  _id: Scalars['ObjectId'];
  /**
   * Available primary stalls with no schedules
   * @deprecated use stats.availableStallCount
   */
  availableStallCount: Scalars['Float'];
  country: Country;
  countryId: Scalars['ObjectId'];
  createdAt: Scalars['DateTime'];
  facilityType: FacilityType;
  location: FacilityLocation;
  name: Scalars['String'];
  /**
   * Facility Sign Rate only for primary stalls
   * @deprecated use stats.netSignRate
   */
  netSignRate: Scalars['Float'];
  /**
   * Includes only Active Primary Stalls. Physical stall count for GFS kitchens
   * @deprecated use stats.numberOfStalls
   */
  numberOfStalls: Scalars['Float'];
  officialName?: Maybe<Scalars['String']>;
  orders: OrderConnection;
  ordersManagement: OrderManagement;
  partners: Array<Partner>;
  staff: Array<CookingStaff>;
  stallIds: Array<Scalars['ObjectId']>;
  stalls: Array<Stall>;
  stats: FacilityStats;
  status: FacilityStatus;
  timezone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vendors: Array<VendorModule>;
};


export type FacilityOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<OrderFieldFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<OrderSort>;
};


export type FacilityStallsArgs = {
  filter?: InputMaybe<StallsFieldFilter>;
};

export type FacilityConnection = Connection & {
  __typename?: 'FacilityConnection';
  /** Use this if you want to do cursor based pagination, otherwise just use `nodes` directly. */
  edges: Array<FacilityEdge>;
  /** Use this directly if you don't want cursor based pagination, otherwise use `edges`. */
  nodes: Array<Facility>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type FacilityEdge = {
  __typename?: 'FacilityEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['Cursor'];
  node: Facility;
};

export type FacilityFilter = {
  countryIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  facilityType?: InputMaybe<FacilityType>;
  partnerIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<FacilityStatus>>;
};

export type FacilityLocation = {
  __typename?: 'FacilityLocation';
  city?: Maybe<Scalars['String']>;
  point?: Maybe<GeoJsonPoint>;
};

export type FacilityLocationInput = {
  city?: InputMaybe<Scalars['String']>;
  point?: InputMaybe<GeoJsonPointInput>;
};

export type FacilityStats = {
  __typename?: 'FacilityStats';
  availableStallCount: Scalars['Float'];
  netSignRate: Scalars['Float'];
  numberOfLiveInsulators: Scalars['Float'];
  numberOfLiveStalls: Scalars['Float'];
  numberOfStalls: Scalars['Float'];
  offboardingScheduleCount: Scalars['Float'];
  onboardingScheduleCount: Scalars['Float'];
};

export enum FacilityStatus {
  Active = 'Active',
  Approved = 'Approved',
  Inactive = 'Inactive',
  LeaseSigned = 'LeaseSigned',
  UnderConstruction = 'UnderConstruction'
}

export enum FacilityType {
  Kitchen = 'Kitchen',
  Store = 'Store'
}

/** Bookmark of brand pool filters for a country */
export type FilterBookmark = TimeStamps & {
  __typename?: 'FilterBookmark';
  _id: Scalars['ObjectId'];
  country: Country;
  countryId: Scalars['ObjectId'];
  createdAt: Scalars['DateTime'];
  /** System inserted filter bookmarks do not have createdBy. Use this field to determine system added views on UI */
  createdBy?: Maybe<Scalars['ObjectId']>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['ObjectId']>;
  /** url params without the first ? */
  urlParams: Scalars['String'];
};

export type FilterBookmarkConnection = Connection & {
  __typename?: 'FilterBookmarkConnection';
  /** Use this if you want to do cursor based pagination, otherwise just use `nodes` directly. */
  edges: Array<FilterBookmarkEdge>;
  /** Use this directly if you don't want cursor based pagination, otherwise use `edges`. */
  nodes: Array<FilterBookmark>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type FilterBookmarkConnectionFilter = {
  countryId?: InputMaybe<Scalars['ObjectId']>;
};

export type FilterBookmarkEdge = {
  __typename?: 'FilterBookmarkEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['Cursor'];
  node: FilterBookmark;
};

export type FilterBookmarkInput = {
  countryId: Scalars['ObjectId'];
  name: Scalars['String'];
  urlParams: Scalars['String'];
};

export type FilterBookmarkUpdate = {
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type GenericSort = {
  sortBy?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type GeoJsonPoint = {
  __typename?: 'GeoJSONPoint';
  coordinates: Array<Scalars['Float']>;
  type: Scalars['String'];
};

export type GeoJsonPointInput = {
  coordinates: Array<Scalars['Float']>;
  type: Scalars['String'];
};

export type History = {
  __typename?: 'History';
  details?: Maybe<Scalars['JSONObject']>;
  externalStatus?: Maybe<Scalars['String']>;
  manual?: Maybe<Scalars['Boolean']>;
  status: OrderStatus;
  timestamp: Scalars['DateTime'];
};

export type ImpersonateReturn = {
  __typename?: 'ImpersonateReturn';
  authorized?: Maybe<Scalars['Boolean']>;
  tokens?: Maybe<Tokens>;
  user?: Maybe<User>;
};

export type ImpersonationUserIdentityInput = {
  email?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type Item = {
  __typename?: 'Item';
  customer_notes?: Maybe<Scalars['String']>;
  customer_notes_detected_locale?: Maybe<Scalars['String']>;
  customer_notes_translated?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  names?: Maybe<Array<LocaleName>>;
  options?: Maybe<Array<Option>>;
  product_parent?: Maybe<OrderProductParent>;
  quantity: Scalars['Float'];
  stations?: Maybe<Array<Scalars['String']>>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** A Lead is generated for each Brand x Facility */
export type Lead = TimeStamps & {
  __typename?: 'Lead';
  _id: Scalars['ObjectId'];
  assignee?: Maybe<User>;
  assigneeId?: Maybe<Scalars['ObjectId']>;
  brand: Brand;
  createdAt: Scalars['DateTime'];
  events: Array<LeadEvent>;
  facility: Facility;
  facilityId: Scalars['ObjectId'];
  globalEntityId?: Maybe<Scalars['String']>;
  /** Is the brand NOT present within the area of the facility (imported from BQ) */
  isEligible?: Maybe<Scalars['Boolean']>;
  lease?: Maybe<Lease>;
  partner?: Maybe<Partner>;
  predictions?: Maybe<LeadPredictions>;
  /** Latest reason, only there if status is `Lost` or `Churned` */
  reason?: Maybe<Scalars['String']>;
  status: LeadStatus;
  updatedAt: Scalars['DateTime'];
  vendorId?: Maybe<Scalars['String']>;
};

export type LeadConnection = Connection & {
  __typename?: 'LeadConnection';
  /** Use this if you want to do cursor based pagination, otherwise just use `nodes` directly. */
  edges: Array<LeadEdge>;
  /** Use this directly if you don't want cursor based pagination, otherwise use `edges`. */
  nodes: Array<Lead>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type LeadConnectionFilter = {
  countryId?: InputMaybe<Scalars['ObjectId']>;
  facilityIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Hide brands with archived */
  hideArchived?: InputMaybe<Scalars['Boolean']>;
  partnerIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Partial match of brand name */
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<LeadStatus>>;
};

export type LeadEdge = {
  __typename?: 'LeadEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['Cursor'];
  node: Lead;
};

export type LeadEvent = {
  __typename?: 'LeadEvent';
  /** Comment related to status update, would be lost reason in case of Lost */
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** This can be used to track who made the status update. Useful for timeline display later */
  createdBy?: Maybe<Scalars['ObjectId']>;
  createdByUser?: Maybe<User>;
  status: LeadStatus;
};

export type LeadFieldFilter = {
  countryId?: InputMaybe<Scalars['ObjectId']>;
  statuses?: InputMaybe<Array<LeadStatus>>;
};

export type LeadPredictions = {
  __typename?: 'LeadPredictions';
  /** Predicted category */
  category?: Maybe<BrandCategory>;
  gbv?: Maybe<Scalars['Float']>;
  leadAreaScore?: Maybe<Scalars['Float']>;
  /** Month (first day of the month) for which lead score was generated. Could be older than updatedAt if brand became inactive and has no recent predictions */
  predictionMonth?: Maybe<Scalars['DateTime']>;
  /** Date when the lead scores was last calculated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum LeadStatus {
  Churned = 'Churned',
  ContractSent = 'ContractSent',
  Lead = 'Lead',
  Live = 'Live',
  Lost = 'Lost',
  Negotiation = 'Negotiation',
  New = 'New',
  Prospect = 'Prospect',
  Signed = 'Signed',
  Suspect = 'Suspect',
  VerballyAgreed = 'VerballyAgreed',
  WaitingList = 'WaitingList'
}

/** Lease for Facility signed by Partner */
export type Lease = TimeStamps & {
  __typename?: 'Lease';
  _id: Scalars['ObjectId'];
  brandAMs?: Maybe<Array<BrandAccountManager>>;
  /** AKA Agreed go live date. Date when the partner is expected to start working according to the lease in the stall */
  commencementDate?: Maybe<Scalars['Date']>;
  /** AKA Rent commencement date. Date of the Start of Lease - payments start from this date */
  contractEffectiveDate?: Maybe<Scalars['Date']>;
  createdAt: Scalars['DateTime'];
  facility: Facility;
  facilityId: Scalars['ObjectId'];
  /** List of URLs related to lease like contracts, company info, etc. */
  files?: Maybe<Array<Scalars['URL']>>;
  /** Date when that brand receives the first order - comes from BI */
  goLiveDate?: Maybe<Scalars['DateTime']>;
  /** Use only for draft leases. Insulator stall ID is not assigned until signing as they are not really physical stalls that need to be blocked */
  hasInsulator?: Maybe<Scalars['Boolean']>;
  leadSigners?: Maybe<Array<User>>;
  leads: Array<Lead>;
  partner: Partner;
  partnerId: Scalars['ObjectId'];
  priceInfo?: Maybe<LeasePriceInfo>;
  /** Date the Lead was move to `Signed` */
  signDate?: Maybe<Scalars['Date']>;
  stallIds: Array<Scalars['ObjectId']>;
  stalls: Array<Stall>;
  status: LeaseStatus;
  /** Duration of lease in Months */
  termInMonths?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};


/** Lease for Facility signed by Partner */
export type LeaseLeadsArgs = {
  filter?: InputMaybe<LeadFieldFilter>;
};

export type LeaseConnection = Connection & {
  __typename?: 'LeaseConnection';
  /** Use this if you want to do cursor based pagination, otherwise just use `nodes` directly. */
  edges: Array<LeaseEdge>;
  /** Use this directly if you don't want cursor based pagination, otherwise use `edges`. */
  nodes: Array<Lease>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type LeaseConnectionFilter = {
  countryId?: InputMaybe<Scalars['ObjectId']>;
  facilityIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  partnerIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  status?: InputMaybe<Array<LeaseStatus>>;
};

export type LeaseEdge = {
  __typename?: 'LeaseEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['Cursor'];
  node: Lease;
};

export type LeaseFieldFilter = {
  countryId?: InputMaybe<Scalars['ObjectId']>;
  status?: InputMaybe<Array<LeaseStatus>>;
};

export type LeaseInput = {
  commencementDate?: InputMaybe<Scalars['Date']>;
  contractEffectiveDate?: InputMaybe<Scalars['Date']>;
  goLiveDate?: InputMaybe<Scalars['Date']>;
  hasInsulator?: InputMaybe<Scalars['Boolean']>;
  priceInfo?: InputMaybe<LeasePriceInfoInput>;
  signDate?: InputMaybe<Scalars['Date']>;
  stallIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  termInMonths?: InputMaybe<Scalars['Int']>;
};

/** Price Data related to lease, there are some `KSA specific` fields, which are currently not send to BI as they are just added for reference for the FTEs locally */
export type LeasePriceInfo = {
  __typename?: 'LeasePriceInfo';
  /** KSA specific - Fixed amount per month | Charge for Cleaning stall in local currency. */
  chemicalsLocal?: Maybe<Scalars['Float']>;
  /** Fixed amount only once | Total Discount given to the partner in local currency */
  discountLocal?: Maybe<Scalars['Float']>;
  /** KSA specific - Fixed amount per order per month | Commission GFS takes in local currency, could be in addition to or replace `incrementalCommissionPercentage`. */
  fixedIncrementalCommissionLocal?: Maybe<Scalars['Float']>;
  /** KSA specific - Fixed amount per month | Utility charges like water, gas and electricity in local currency. */
  gTSubscriptionLocal?: Maybe<Scalars['Float']>;
  /** Percentage of the orders revenue per month | The commission GFS takes on top of the platform commission, this could be 0 in favor of `fixedIncrementalCommissionLocal` */
  incrementalCommissionPercentage?: Maybe<Scalars['Float']>;
  /** KSA specific - Fixed amount per month | Internet usage charges in local currency. */
  internetPriceLocal?: Maybe<Scalars['Float']>;
  /** KSA specific - Percentage of the non-DH orders revenue per month | Commission charged for other platforms since KSA is non exclusive */
  nonDHCommissionPercentage?: Maybe<Scalars['Float']>;
  /** KSA specific - Percentage of the orders revenue per month | Pickup orders commission charged as they are normally higher than IC% */
  pickupCommissionPercentage?: Maybe<Scalars['Float']>;
  /** Fixed amount per month | Monthly gross rent revenue of ALL the stalls of the partner in the current kitchen in local currency. (might include monthly charged fixed amounts) */
  rentRevenueLocal?: Maybe<Scalars['Float']>;
};

export type LeasePriceInfoInput = {
  /** KSA specific - Fixed amount per month | Charge for Cleaning stall in local currency. */
  chemicalsLocal?: InputMaybe<Scalars['Float']>;
  /** Fixed amount only once | Total Discount given to the partner in local currency */
  discountLocal?: InputMaybe<Scalars['Float']>;
  /** KSA specific - Fixed amount per order per month | Commission GFS takes in local currency, could be in addition to or replace `incrementalCommissionPercentage`. */
  fixedIncrementalCommissionLocal?: InputMaybe<Scalars['Float']>;
  /** KSA specific - Fixed amount per month | Utility charges like water, gas and electricity in local currency. */
  gTSubscriptionLocal?: InputMaybe<Scalars['Float']>;
  /** Percentage of the orders revenue per month | The commission GFS takes on top of the platform commission, this could be 0 in favor of `fixedIncrementalCommissionLocal` */
  incrementalCommissionPercentage?: InputMaybe<Scalars['Float']>;
  /** KSA specific - Fixed amount per month | Internet usage charges in local currency. */
  internetPriceLocal?: InputMaybe<Scalars['Float']>;
  /** KSA specific - Percentage of the non-DH orders revenue per month | Commission charged for other platforms since KSA is non exclusive */
  nonDHCommissionPercentage?: InputMaybe<Scalars['Float']>;
  /** KSA specific - Percentage of the orders revenue per month | Pickup orders commission charged as they are normally higher than IC% */
  pickupCommissionPercentage?: InputMaybe<Scalars['Float']>;
  /** Fixed amount per month | Monthly gross rent revenue of ALL the stalls of the partner in the current kitchen in local currency. (might include monthly charged fixed amounts) */
  rentRevenueLocal?: InputMaybe<Scalars['Float']>;
};

export enum LeaseStatus {
  Churned = 'Churned',
  Draft = 'Draft',
  Live = 'Live',
  Signed = 'Signed'
}

export type LocaleName = {
  __typename?: 'LocaleName';
  locale: Scalars['String'];
  value: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  address_text?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  postal_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type LoginResult = {
  __typename?: 'LoginResult';
  sessionId?: Maybe<Scalars['String']>;
  tokens?: Maybe<Tokens>;
  user?: Maybe<User>;
};

/** Mark Brand lost input */
export type MarkBrandLostInput = {
  comment: Scalars['String'];
  reason: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addEmail?: Maybe<Scalars['Boolean']>;
  /** Adds a note to Brands.notes array */
  addNote?: Maybe<Brand>;
  /** Assign leads of a single brand */
  assignLeads: Array<Lead>;
  authenticate?: Maybe<LoginResult>;
  /** Bulk assign leads of multiple brands */
  bulkAssignBrands: Array<Brand>;
  cancelAction: Action;
  changePassword?: Maybe<Scalars['Boolean']>;
  country: Country;
  /** @deprecated This is only created for testing, not to be used in prod */
  createAction: Action;
  createBookmark: FilterBookmark;
  createBrand: Brand;
  createFacility: Facility;
  /** @deprecated Sign ups for SSO users are automatic now, for password users use createNewPasswordUser */
  createNewUser: User;
  createPartner: Partner;
  createPasswordUser: User;
  createStall: Stall;
  createUser?: Maybe<CreateUserResult>;
  createVendor: VendorModule;
  deleteBookmarks: Scalars['Int'];
  deletePartnerAssignee?: Maybe<Partner>;
  deletePartnerContact?: Maybe<Partner>;
  detachVendor: VendorModule;
  discardStallSchedule: Stall;
  executeAction: Action;
  impersonate?: Maybe<ImpersonateReturn>;
  insertCookingStaffCounts?: Maybe<Array<CookingStaff>>;
  logout?: Maybe<Scalars['Boolean']>;
  markBrandLost: Brand;
  mergeDuplicatePartners: Array<Partner>;
  refreshTokens?: Maybe<LoginResult>;
  resetPassword?: Maybe<LoginResult>;
  sendResetPasswordEmail: Scalars['Boolean'];
  sendVerificationEmail?: Maybe<Scalars['Boolean']>;
  setAnnouncement?: Maybe<Facility>;
  /** @deprecated Only for migration, don't use anywhere else */
  setPartnerToStall: Stall;
  /** @deprecated Only for migration, don't use anywhere else */
  setStallForVendor: VendorModule;
  skipRecommendedBrand: Brand;
  /** @deprecated Maintain user's facility state in client */
  switchKitchen: User;
  switchUserState: User;
  twoFactorSet?: Maybe<Scalars['Boolean']>;
  twoFactorUnset?: Maybe<Scalars['Boolean']>;
  updateBookmark: FilterBookmark;
  updateBookmarks: Array<FilterBookmark>;
  updateBrand: Brand;
  updateFacility: Facility;
  /** Update status of leads of a single brand */
  updateLeadsStatus: Array<Lead>;
  updateOrder: Order;
  updatePartner: Partner;
  updatePartnerAssignees: Partner;
  updatePartnerContact: Partner;
  updatePasswordUser: User;
  updateStall: Stall;
  updateStations: Array<Product>;
  updateUser: User;
  updateVendor: VendorModule;
  upsertStallOffboarding: Stall;
  upsertStallOnboarding: Stall;
  verifyAuthentication?: Maybe<Scalars['Boolean']>;
  verifyEmail?: Maybe<Scalars['Boolean']>;
};


export type MutationAddEmailArgs = {
  newEmail: Scalars['String'];
};


export type MutationAddNoteArgs = {
  brandId: Scalars['ObjectId'];
  data: BrandNoteInput;
};


export type MutationAssignLeadsArgs = {
  data: AssignLeadsInput;
};


export type MutationAuthenticateArgs = {
  params: AuthenticateParamsInput;
  serviceName: Scalars['String'];
};


export type MutationBulkAssignBrandsArgs = {
  data: BulkAssignInput;
};


export type MutationCancelActionArgs = {
  actionId: Scalars['ObjectId'];
  message: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationCountryArgs = {
  id?: InputMaybe<Scalars['ObjectId']>;
  input: CountryForm;
};


export type MutationCreateActionArgs = {
  actionType: ActionType;
  content: Scalars['JSONObject'];
  status?: InputMaybe<ActionStatus>;
  subject: EntityInput;
  timestamp?: InputMaybe<Scalars['DateTime']>;
};


export type MutationCreateBookmarkArgs = {
  data: FilterBookmarkInput;
};


export type MutationCreateBrandArgs = {
  data: CreateBrandInput;
};


export type MutationCreateFacilityArgs = {
  data: CreateFacilityInput;
};


export type MutationCreateNewUserArgs = {
  user: CreateUserInput;
};


export type MutationCreatePartnerArgs = {
  data: CreatePartnerInput;
};


export type MutationCreatePasswordUserArgs = {
  data: CreatePasswordUserInput;
};


export type MutationCreateStallArgs = {
  facilityId: Scalars['ObjectId'];
  type: StallType;
};


export type MutationCreateUserArgs = {
  user: CreateUserInput;
};


export type MutationCreateVendorArgs = {
  data: CreateVendorInput;
};


export type MutationDeleteBookmarksArgs = {
  deleteIds: Array<Scalars['ObjectId']>;
};


export type MutationDeletePartnerAssigneeArgs = {
  id: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
};


export type MutationDeletePartnerContactArgs = {
  id: Scalars['ObjectId'];
  partnerContactId: Scalars['ObjectId'];
};


export type MutationDetachVendorArgs = {
  stallId: Scalars['ObjectId'];
  vendor: DetachVendorInput;
};


export type MutationDiscardStallScheduleArgs = {
  data?: InputMaybe<StallScheduleDiscardInput>;
  scheduleType: EventType;
  stallId: Scalars['ObjectId'];
};


export type MutationExecuteActionArgs = {
  actionId: Scalars['ObjectId'];
};


export type MutationImpersonateArgs = {
  accessToken: Scalars['String'];
  impersonated: ImpersonationUserIdentityInput;
};


export type MutationInsertCookingStaffCountsArgs = {
  cookingStaffs: Array<CreateCookingStaffInput>;
};


export type MutationMarkBrandLostArgs = {
  data: MarkBrandLostInput;
  id: Scalars['ObjectId'];
};


export type MutationMergeDuplicatePartnersArgs = {
  id: Scalars['ObjectId'];
  partnerIds: Array<Scalars['ObjectId']>;
};


export type MutationRefreshTokensArgs = {
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSendResetPasswordEmailArgs = {
  email: Scalars['String'];
};


export type MutationSendVerificationEmailArgs = {
  email: Scalars['String'];
};


export type MutationSetAnnouncementArgs = {
  announcement: Scalars['String'];
  facilityId: Scalars['ObjectId'];
};


export type MutationSetPartnerToStallArgs = {
  partnerId: Scalars['ObjectId'];
  stallId: Scalars['ObjectId'];
};


export type MutationSetStallForVendorArgs = {
  stallId?: InputMaybe<Scalars['ObjectId']>;
  stallNumber?: InputMaybe<Scalars['Int']>;
  vendor: VendorFilter;
};


export type MutationSkipRecommendedBrandArgs = {
  brandId: Scalars['ObjectId'];
  skipReason: Scalars['String'];
};


export type MutationSwitchKitchenArgs = {
  id: Scalars['ID'];
};


export type MutationSwitchUserStateArgs = {
  id: Scalars['ID'];
  state: Scalars['Boolean'];
};


export type MutationTwoFactorSetArgs = {
  code: Scalars['String'];
  secret: TwoFactorSecretKeyInput;
};


export type MutationTwoFactorUnsetArgs = {
  code: Scalars['String'];
};


export type MutationUpdateBookmarkArgs = {
  data: FilterBookmarkUpdate;
};


export type MutationUpdateBookmarksArgs = {
  data: Array<FilterBookmarkUpdate>;
};


export type MutationUpdateBrandArgs = {
  data: UpdateBrandInput;
  id: Scalars['ObjectId'];
};


export type MutationUpdateFacilityArgs = {
  data: UpdateFacilityInput;
  id: Scalars['ObjectId'];
};


export type MutationUpdateLeadsStatusArgs = {
  data: UpdateLeadsStatusInput;
};


export type MutationUpdateOrderArgs = {
  facilityId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  update: OrderUpdate;
};


export type MutationUpdatePartnerArgs = {
  data: UpdatePartnerInput;
  id: Scalars['ObjectId'];
};


export type MutationUpdatePartnerAssigneesArgs = {
  data: PartnerAssigneeInput;
  partnerId: Scalars['ObjectId'];
};


export type MutationUpdatePartnerContactArgs = {
  data: PartnerContactInput;
  id: Scalars['ObjectId'];
};


export type MutationUpdatePasswordUserArgs = {
  data: UpdatePasswordUserInput;
  id: Scalars['ID'];
};


export type MutationUpdateStallArgs = {
  data: UpdateStallInput;
  id: Scalars['ObjectId'];
};


export type MutationUpdateStationsArgs = {
  productIdMap: Scalars['JSONObject'];
  vendor: VendorFilter;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  user: UpdateUserInput;
};


export type MutationUpdateVendorArgs = {
  data: UpdateVendorInput;
  vendor: VendorFilter;
};


export type MutationUpsertStallOffboardingArgs = {
  data: StallOffboardingInput;
  execute?: InputMaybe<Scalars['Boolean']>;
  stallId: Scalars['ObjectId'];
};


export type MutationUpsertStallOnboardingArgs = {
  data: StallOnboardingInput;
  execute?: InputMaybe<Scalars['Boolean']>;
  stallId: Scalars['ObjectId'];
};


export type MutationVerifyAuthenticationArgs = {
  params: AuthenticateParamsInput;
  serviceName: Scalars['String'];
};


export type MutationVerifyEmailArgs = {
  token: Scalars['String'];
};

/** offboarding details of stall */
export type OffboardingDetails = TimeStamps & {
  __typename?: 'OffboardingDetails';
  _id: Scalars['ObjectId'];
  /** @deprecated use lease.brandAMs instead */
  brandAMs?: Maybe<Array<BrandAccountManager>>;
  /** Additional comment */
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** userId creating the schedule */
  createdBy: Scalars['ObjectId'];
  /** @deprecated use lease.leadSigners instead */
  leadSigners?: Maybe<Array<User>>;
  /** Might not exist if onboarding not done from Sales */
  lease?: Maybe<Lease>;
  partner: Partner;
  /** Partner that will be onboarded/offboarded to the stall */
  partnerId: Scalars['ObjectId'];
  /** Reasons for offboarding */
  reasons?: Maybe<Array<OffboardingReason>>;
  /** Date when the stall will be onboarded/offboarded */
  scheduledAt: Scalars['DateTime'];
  status?: Maybe<StallScheduleStatus>;
  updatedAt: Scalars['DateTime'];
  /** userId updating the schedule */
  updatedBy: Scalars['ObjectId'];
};

export enum OffboardingReason {
  AccidentalOffboarding = 'AccidentalOffboarding',
  ChurnInOnboarding = 'ChurnInOnboarding',
  CuisineNotFit = 'CuisineNotFit',
  ForceChurn = 'ForceChurn',
  NegativePnl = 'NegativePnl',
  Other = 'Other',
  PartnerMovingFacility = 'PartnerMovingFacility',
  PartnerMovingStall = 'PartnerMovingStall',
  PartnerOpeningOwnStore = 'PartnerOpeningOwnStore',
  PartnerOperationalIssue = 'PartnerOperationalIssue',
  SwitchingStallWrongOnboarding = 'SwitchingStallWrongOnboarding'
}

/** boarding details of stall */
export type OnboardingDetails = TimeStamps & {
  __typename?: 'OnboardingDetails';
  _id: Scalars['ObjectId'];
  /** @deprecated use lease.brandAMs instead */
  brandAMs?: Maybe<Array<BrandAccountManager>>;
  /** Additional comment */
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** userId creating the schedule */
  createdBy: Scalars['ObjectId'];
  /** @deprecated use lease.leadSigners instead */
  leadSigners?: Maybe<Array<User>>;
  /** Might not exist if onboarding not done from Sales */
  lease?: Maybe<Lease>;
  partner: Partner;
  /** Partner that will be onboarded/offboarded to the stall */
  partnerId: Scalars['ObjectId'];
  /** Date when the stall will be onboarded/offboarded */
  scheduledAt: Scalars['DateTime'];
  status?: Maybe<StallScheduleStatus>;
  updatedAt: Scalars['DateTime'];
  /** userId updating the schedule */
  updatedBy: Scalars['ObjectId'];
};

export type Option = {
  __typename?: 'Option';
  customer_notes?: Maybe<Scalars['String']>;
  customer_notes_detected_locale?: Maybe<Scalars['String']>;
  customer_notes_translated?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  names?: Maybe<Array<LocaleName>>;
  options?: Maybe<Array<Option>>;
  quantity: Scalars['Float'];
  type: Scalars['String'];
  unit_price?: Maybe<Scalars['Float']>;
};

/** The Order model */
export type Order = TimeStamps & {
  __typename?: 'Order';
  _id: Scalars['ID'];
  brand: Scalars['String'];
  cancellationReason?: Maybe<Scalars['String']>;
  cancelledBy?: Maybe<OrderStatusOwnerType>;
  comment?: Maybe<OrderComment>;
  completedAt?: Maybe<Scalars['DateTime']>;
  content: OrderContent;
  createdAt: Scalars['DateTime'];
  customColumns?: Maybe<Array<OrderCustomColumns>>;
  delay?: Maybe<Scalars['Float']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  deliveries?: Maybe<Array<OrderDelivery>>;
  facility: Facility;
  facilityId: Scalars['ObjectId'];
  geid_vid: Scalars['String'];
  history: Array<History>;
  partner: Partner;
  partnerId: Scalars['ObjectId'];
  pickedUpAt?: Maybe<Scalars['DateTime']>;
  pickupStation?: Maybe<Scalars['String']>;
  pickupTimeEstimate?: Maybe<Scalars['DateTime']>;
  prepStatusTaskName?: Maybe<Scalars['String']>;
  preparationTimeEstimate?: Maybe<Scalars['DateTime']>;
  preparedAt?: Maybe<Scalars['DateTime']>;
  receivedAt: Scalars['DateTime'];
  shortCode?: Maybe<Scalars['String']>;
  stallIds?: Maybe<Array<Scalars['ObjectId']>>;
  stalls: Array<Stall>;
  status: OrderStatus;
  timestamps?: Maybe<OrderTimestamps>;
  totalBags?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  vendor: VendorModule;
};

export type OrderComment = {
  __typename?: 'OrderComment';
  customerComment?: Maybe<Scalars['String']>;
  translatedCustomerComment?: Maybe<Scalars['String']>;
  translatedCustomerCommentDetectedLocale?: Maybe<Scalars['String']>;
};

export type OrderConnection = Connection & {
  __typename?: 'OrderConnection';
  /** Use this if you want to do cursor based pagination, otherwise just use `nodes` directly. */
  edges: Array<OrderEdge>;
  /** Use this directly if you don't want cursor based pagination, otherwise use `edges`. */
  nodes: Array<Order>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type OrderContent = {
  __typename?: 'OrderContent';
  brand_name: Scalars['String'];
  business_type: Scalars['String'];
  commission?: Maybe<Commission>;
  corporate: Scalars['Boolean'];
  country_code: Scalars['String'];
  currency: Scalars['String'];
  customer: Customer;
  delivery: Delivery;
  global_entity_id: Scalars['String'];
  locale: Scalars['String'];
  order: OrderInfo;
  order_id: Scalars['String'];
  preorder: Scalars['Boolean'];
  promised_customer_timestamp?: Maybe<Scalars['DateTime']>;
  test_order: Scalars['Boolean'];
  timestamp: Scalars['DateTime'];
  vendor: VendorInfo;
};

export type OrderCustomColumns = {
  __typename?: 'OrderCustomColumns';
  id: Scalars['String'];
  value: Scalars['String'];
};

export type OrderCustomColumnsInput = {
  id: Scalars['String'];
  value: Scalars['String'];
};

export type OrderDelivery = {
  __typename?: 'OrderDelivery';
  deliveryId?: Maybe<Scalars['String']>;
  deliveryIdsGroup?: Maybe<Array<Scalars['String']>>;
  estimatedPickupAt?: Maybe<Scalars['DateTime']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  pickedUpAt?: Maybe<Scalars['DateTime']>;
  riderFullName?: Maybe<Scalars['String']>;
  riderName?: Maybe<Scalars['String']>;
  riderPhoneNumber?: Maybe<Scalars['String']>;
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['Cursor'];
  node: Order;
};

export type OrderFieldFilter = {
  /** Uses receivedAt */
  date?: InputMaybe<DateFilterInput>;
  paymentMethod?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<OrderStatus>>;
};

export type OrderFilter = {
  /** Uses receivedAt */
  date?: InputMaybe<DateFilterInput>;
  facilityId?: InputMaybe<Scalars['ObjectId']>;
  paymentMethod?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<OrderStatus>>;
  /** Will take priority over facilityId */
  vendors?: InputMaybe<Array<VendorFilter>>;
};

export type OrderInfo = {
  __typename?: 'OrderInfo';
  deposit?: Maybe<Scalars['Float']>;
  items: Array<Item>;
  minimum_order_value?: Maybe<Scalars['Float']>;
  order_value?: Maybe<Scalars['Float']>;
  tax?: Maybe<Tax>;
};

/** Settings for OMS */
export type OrderManagement = {
  __typename?: 'OrderManagement';
  announcement?: Maybe<Scalars['String']>;
  customColumns?: Maybe<Array<CustomColumns>>;
  defaultPageInterval?: Maybe<Scalars['Float']>;
  defaultPageSize?: Maybe<Scalars['Float']>;
  inPreparationHeader?: Maybe<Scalars['String']>;
  readyForPickupHeader?: Maybe<Scalars['String']>;
  usePickupStation?: Maybe<Scalars['Boolean']>;
};

export type OrderManagementInput = {
  announcement?: InputMaybe<Scalars['String']>;
  customColumns?: InputMaybe<Array<CustomColumnsInput>>;
  defaultPageInterval?: InputMaybe<Scalars['Float']>;
  defaultPageSize?: InputMaybe<Scalars['Float']>;
  inPreparationHeader?: InputMaybe<Scalars['String']>;
  readyForPickupHeader?: InputMaybe<Scalars['String']>;
  usePickupStation?: InputMaybe<Scalars['Boolean']>;
};

export type OrderProductParent = {
  __typename?: 'OrderProductParent';
  name?: Maybe<Scalars['String']>;
  names?: Maybe<Array<LocaleName>>;
  product_parent_id?: Maybe<Scalars['String']>;
};

export type OrderSort = {
  sortBy?: InputMaybe<OrderSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

/** Order sort by options */
export enum OrderSortBy {
  Customer = 'Customer',
  OrderId = 'OrderId',
  PaymentMethod = 'PaymentMethod',
  ReceivedAt = 'ReceivedAt',
  Status = 'Status',
  TotalOrderValue = 'TotalOrderValue',
  VendorId = 'VendorId',
  VendorName = 'VendorName'
}

export enum OrderStatus {
  Cancelled = 'Cancelled',
  Closed = 'Closed',
  CourierNearPickup = 'CourierNearPickup',
  Delivered = 'Delivered',
  DisplayedAtVendor = 'DisplayedAtVendor',
  InPreparation = 'InPreparation',
  PickedUp = 'PickedUp',
  ReadyForPacking = 'ReadyForPacking',
  ReadyForPickup = 'ReadyForPickup',
  Received = 'Received',
  RiderAssigned = 'RiderAssigned',
  ShortCodeAssigned = 'ShortCodeAssigned',
  UndoReadyForPacking = 'UndoReadyForPacking',
  Unknown = 'Unknown'
}

/** How should order status transition happen for vendors */
export enum OrderStatusChange {
  Automatic = 'Automatic',
  External = 'External',
  Manual = 'Manual',
  Pepper = 'Pepper'
}

export enum OrderStatusOwnerType {
  Customer = 'CUSTOMER',
  Fulfilment = 'FULFILMENT',
  Platform = 'PLATFORM',
  Transport = 'TRANSPORT',
  Vendor = 'VENDOR'
}

export type OrderSubscriptionFilter = {
  vendors?: InputMaybe<Array<VendorFilter>>;
};

export type OrderTimestamps = {
  __typename?: 'OrderTimestamps';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  displayedAtVendorAt?: Maybe<Scalars['DateTime']>;
  readyForPackingAt?: Maybe<Scalars['DateTime']>;
};

export type OrderUpdate = {
  customColumns?: InputMaybe<Array<OrderCustomColumnsInput>>;
  pickupStation?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<OrderStatus>;
  totalBags?: InputMaybe<Scalars['Float']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['Cursor']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['Cursor']>;
};

export type Partner = TimeStamps & {
  __typename?: 'Partner';
  _id: Scalars['ObjectId'];
  assignees?: Maybe<Array<PartnerAssignee>>;
  /** All brand's for the Partner (by default includes all countries) */
  brands: BrandsFieldConnection;
  contacts?: Maybe<Array<PartnerContact>>;
  createdAt: Scalars['DateTime'];
  events: Array<PartnerEvent>;
  facilityType: FacilityType;
  isUnassignedPartner: Scalars['Boolean'];
  leases: LeaseConnection;
  name: Scalars['String'];
  stalls: Array<Stall>;
  status: PartnerStatus;
  updatedAt: Scalars['DateTime'];
  vendors: Array<VendorModule>;
};


export type PartnerBrandsArgs = {
  filter?: InputMaybe<BrandFieldFilter>;
};


export type PartnerLeasesArgs = {
  filter?: InputMaybe<LeaseFieldFilter>;
};

export type PartnerAssignee = {
  __typename?: 'PartnerAssignee';
  country: Country;
  countryId: Scalars['ObjectId'];
  type: PartnerAssigneeType;
  user: User;
  userId: Scalars['ObjectId'];
};

export type PartnerAssigneeInput = {
  countryId: Scalars['ObjectId'];
  type: PartnerAssigneeType;
  userIds: Array<Scalars['String']>;
};

export enum PartnerAssigneeType {
  GrowthManager = 'GrowthManager'
}

export type PartnerConnection = Connection & {
  __typename?: 'PartnerConnection';
  /** Use this if you want to do cursor based pagination, otherwise just use `nodes` directly. */
  edges: Array<PartnerEdge>;
  /** Use this directly if you don't want cursor based pagination, otherwise use `edges`. */
  nodes: Array<Partner>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PartnerConnectionFilter = {
  facilityIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  facilityType?: InputMaybe<FacilityType>;
  partnerIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<PartnerStatus>>;
};

export type PartnerContact = {
  __typename?: 'PartnerContact';
  _id: Scalars['ObjectId'];
  country: Country;
  countryId: Scalars['ObjectId'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  /** Preferred language for communication, if it's empty we assume English */
  language?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: ContactType;
};

export type PartnerContactInput = {
  _id?: InputMaybe<Scalars['ObjectId']>;
  countryId: Scalars['ObjectId'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type: ContactType;
};

export type PartnerEdge = {
  __typename?: 'PartnerEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['Cursor'];
  node: Partner;
};

/** Stall Events Data */
export type PartnerEvent = TimeStamps & {
  __typename?: 'PartnerEvent';
  _id: Scalars['ObjectId'];
  /** Additional comment */
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** Reasons for offboarding */
  reasons?: Maybe<Array<OffboardingReason>>;
  stall: Stall;
  stallId: Scalars['ObjectId'];
  type: PartnerEventType;
  updatedAt: Scalars['DateTime'];
};

export enum PartnerEventType {
  OffBoarding = 'OffBoarding',
  OffBoardingScheduled = 'OffBoardingScheduled',
  OffboardingCancelled = 'OffboardingCancelled',
  OnBoarding = 'OnBoarding',
  OnBoardingScheduled = 'OnBoardingScheduled',
  OnboardingCancelled = 'OnboardingCancelled'
}

export enum PartnerStatus {
  Active = 'Active',
  Draft = 'Draft',
  Duplicate = 'Duplicate',
  Inactive = 'Inactive'
}

export type Payment = {
  __typename?: 'Payment';
  coupon?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  loyalty_point_balance?: Maybe<Scalars['Float']>;
  minimum_order_value_fee?: Maybe<Scalars['Float']>;
  paid: Scalars['Boolean'];
  payment_method: Scalars['String'];
  service_fee?: Maybe<Scalars['Float']>;
  total_order_value: Scalars['Float'];
  total_tax?: Maybe<Scalars['Float']>;
  transaction_number?: Maybe<Scalars['String']>;
  wallet_balance?: Maybe<Scalars['Float']>;
};

export enum PipelineStatus {
  ColdLead = 'ColdLead',
  InProgress = 'InProgress',
  InTraction = 'InTraction',
  None = 'None'
}

/** The Product model */
export type Product = TimeStamps & {
  __typename?: 'Product';
  _id: Scalars['ID'];
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  descriptions?: Maybe<Array<LocaleName>>;
  globalEntityId: Scalars['String'];
  name: Scalars['String'];
  names?: Maybe<Array<LocaleName>>;
  productId: Scalars['String'];
  productOptionIds?: Maybe<Array<Scalars['String']>>;
  productParent?: Maybe<ProductParent>;
  stations?: Maybe<Array<Scalars['String']>>;
  updatedAt: Scalars['DateTime'];
  vendorId: Scalars['String'];
};

export type ProductConnection = Connection & {
  __typename?: 'ProductConnection';
  /** Use this if you want to do cursor based pagination, otherwise just use `nodes` directly. */
  edges: Array<ProductEdge>;
  /** Use this directly if you don't want cursor based pagination, otherwise use `edges`. */
  nodes: Array<Product>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ProductEdge = {
  __typename?: 'ProductEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['Cursor'];
  node: Product;
};

export type ProductFilter = {
  vendor?: InputMaybe<VendorFilter>;
};

export type ProductParent = {
  __typename?: 'ProductParent';
  name?: Maybe<Scalars['String']>;
  productParentId?: Maybe<Scalars['String']>;
};

export type Profile = {
  __typename?: 'Profile';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  guest?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  allActions: ActionConnection;
  allBookmarks: FilterBookmarkConnection;
  allBrands: BrandConnection;
  allCountries: CountryConnection;
  allFacilities: FacilityConnection;
  allLeads?: Maybe<LeadConnection>;
  allLeases: LeaseConnection;
  allOrders: OrderConnection;
  allPartners: PartnerConnection;
  allProducts: ProductConnection;
  /** @deprecated Use allUsers with filters */
  allSalesUsers?: Maybe<Array<User>>;
  allStallSchedules: StallScheduleDetailsConnection;
  allStalls: StallConnection;
  allUsers: UserConnection;
  allVendors: VendorConnection;
  brand: Brand;
  brandStatsInfo: BrandStatsInfo;
  facility?: Maybe<Facility>;
  /** @deprecated Use allCountries instead */
  getAllCountries?: Maybe<Array<Country>>;
  getCountry?: Maybe<Country>;
  /** @deprecated No longer using cloud builds, replaced with app version check */
  getLatestBuild: Scalars['String'];
  getPaymentMethods: Array<Scalars['String']>;
  getUser?: Maybe<User>;
  getUserById: User;
  lead: Lead;
  me?: Maybe<User>;
  partner?: Maybe<Partner>;
  possiblyDuplicatePartners: PartnerConnection;
  product?: Maybe<Product>;
  recommendedBrands?: Maybe<Array<Brand>>;
  stall?: Maybe<Stall>;
  stallStatusAssignee: Array<StallStatusAssignee>;
  twoFactorSecret?: Maybe<TwoFactorSecretKey>;
  vendor?: Maybe<VendorModule>;
  vendorMetrics: Array<VendorWithMetrics>;
};


export type QueryAllActionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<ActionsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ActionSort>;
};


export type QueryAllBookmarksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<FilterBookmarkConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<DefaultSort>;
};


export type QueryAllBrandsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<BrandConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<GenericSort>;
};


export type QueryAllCountriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<CountryConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<DefaultSort>;
};


export type QueryAllFacilitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<FacilityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<DefaultSort>;
};


export type QueryAllLeadsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<LeadConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<DefaultSort>;
};


export type QueryAllLeasesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<LeaseConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<DefaultSort>;
};


export type QueryAllOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<OrderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<OrderSort>;
};


export type QueryAllPartnersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<PartnerConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<DefaultSort>;
};


export type QueryAllProductsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<ProductFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<DefaultSort>;
};


export type QueryAllSalesUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<UsersFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<UserSort>;
};


export type QueryAllStallSchedulesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<StallScheduleDetailsConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<StallScheduleSort>;
};


export type QueryAllStallsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<StallConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<DefaultSort>;
};


export type QueryAllUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<UsersFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<UserSort>;
};


export type QueryAllVendorsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<VendorConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<DefaultSort>;
};


export type QueryBrandArgs = {
  id: Scalars['ObjectId'];
};


export type QueryBrandStatsInfoArgs = {
  countryId: Scalars['ObjectId'];
};


export type QueryFacilityArgs = {
  id: Scalars['ObjectId'];
};


export type QueryGetCountryArgs = {
  id: Scalars['ObjectId'];
};


export type QueryGetPaymentMethodsArgs = {
  facilityId: Scalars['ObjectId'];
};


export type QueryGetUserByIdArgs = {
  id: Scalars['ID'];
};


export type QueryLeadArgs = {
  id: Scalars['ObjectId'];
};


export type QueryPartnerArgs = {
  id: Scalars['ObjectId'];
};


export type QueryPossiblyDuplicatePartnersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<PartnerConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<DefaultSort>;
};


export type QueryProductArgs = {
  productId: Scalars['String'];
  vendor: VendorFilter;
};


export type QueryRecommendedBrandsArgs = {
  countryId: Scalars['ObjectId'];
};


export type QueryStallArgs = {
  id: Scalars['ObjectId'];
};


export type QueryStallStatusAssigneeArgs = {
  stallId: Scalars['ObjectId'];
};


export type QueryVendorArgs = {
  vendor: VendorFilter;
};


export type QueryVendorMetricsArgs = {
  facilityId: Scalars['ObjectId'];
};

export type Recommendation = {
  __typename?: 'Recommendation';
  createdAt?: Maybe<Scalars['DateTime']>;
  isRecommended?: Maybe<Scalars['Boolean']>;
  isSkipped?: Maybe<Scalars['Boolean']>;
  skipReason?: Maybe<Scalars['String']>;
  skippedAt?: Maybe<Scalars['DateTime']>;
  skippedBy?: Maybe<Scalars['ObjectId']>;
  type?: Maybe<RecommendationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum RecommendationType {
  Eligible50 = 'Eligible50',
  GlobalKa = 'GlobalKA',
  NewInCountry = 'NewInCountry'
}

export enum Roles {
  Admin = 'Admin',
  BusinessDeveloper = 'BusinessDeveloper',
  BusinessDevelopmentManager = 'BusinessDevelopmentManager',
  Dashboard = 'Dashboard',
  Manager = 'Manager',
  Partner = 'Partner',
  Picker = 'Picker',
  Runner = 'Runner'
}

export type SalesAppSettings = {
  __typename?: 'SalesAppSettings';
  partnerContactEmailRequired?: Maybe<Scalars['Boolean']>;
};

export type SalesAppSettingsInput = {
  partnerContactEmailRequired?: InputMaybe<Scalars['Boolean']>;
};

export type SevenDaysAggregatedStats = {
  __typename?: 'SevenDaysAggregatedStats';
  id: Scalars['ID'];
  vendorDelayRate10m: Scalars['Float'];
};

/** Default is desc */
export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Stall = TimeStamps & {
  __typename?: 'Stall';
  _id: Scalars['ObjectId'];
  cookingAppSettings?: Maybe<CookingAppDisplaySettings>;
  createdAt: Scalars['DateTime'];
  data?: Maybe<StallData>;
  facility: Facility;
  facilityId: Scalars['ObjectId'];
  label: Scalars['String'];
  lease?: Maybe<Lease>;
  metrics: CookingMetrics;
  number: Scalars['Int'];
  orders: OrderConnection;
  partner?: Maybe<Partner>;
  partnerId?: Maybe<Scalars['ObjectId']>;
  schedule?: Maybe<StallSchedule>;
  /** @deprecated Feature removed */
  stations: Array<Scalars['String']>;
  status: StallStatus;
  /** Suffix attribute for Shared stalls only */
  suffix?: Maybe<Scalars['Int']>;
  type: StallType;
  updatedAt: Scalars['DateTime'];
  vendors: Array<VendorModule>;
};


export type StallOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<OrderFieldFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<OrderSort>;
};

export type StallConnection = Connection & {
  __typename?: 'StallConnection';
  /** Use this if you want to do cursor based pagination, otherwise just use `nodes` directly. */
  edges: Array<StallEdge>;
  /** Use this directly if you don't want cursor based pagination, otherwise use `edges`. */
  nodes: Array<Stall>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type StallConnectionFilter = {
  countryIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  facilityIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  facilityType?: InputMaybe<FacilityType>;
  occupancy?: InputMaybe<StallOccupancy>;
  partnerIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  search?: InputMaybe<Scalars['String']>;
  stallIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  stallNumber?: InputMaybe<Scalars['Int']>;
  /** Default is Active */
  stallStatuses?: InputMaybe<Array<StallStatus>>;
  /** Filter facilities by status */
  statuses?: InputMaybe<Array<FacilityStatus>>;
  types?: InputMaybe<Array<StallType>>;
};

/** Data related to stall's physical attributes like area, equipment, etc */
export type StallData = {
  __typename?: 'StallData';
  /** In Square Meter */
  areaInSqMt?: Maybe<Scalars['Float']>;
  hasMistHood?: Maybe<Scalars['Boolean']>;
  /** Power input for stall in Kilo Watts(kw) */
  powerInKw?: Maybe<Scalars['Int']>;
};

export type StallDataInput = {
  /** In Square Meter */
  areaInSqMt?: InputMaybe<Scalars['Float']>;
  hasMistHood?: InputMaybe<Scalars['Boolean']>;
  /** Power input for stall in Kilo Watts(kw) */
  powerInKw?: InputMaybe<Scalars['Int']>;
};

export type StallEdge = {
  __typename?: 'StallEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['Cursor'];
  node: Stall;
};

export enum StallOccupancy {
  All = 'All',
  Assigned = 'Assigned',
  Empty = 'Empty',
  Occupied = 'Occupied',
  Unassigned = 'Unassigned'
}

export type StallOffboardingContent = ActionContentBase & {
  __typename?: 'StallOffboardingContent';
  actionType: ActionType;
  /**
   * Use this to document the change request.
   * For eg. user input text for Sales/Admin, jira tickets or slack discussions for migrations.
   */
  comment?: Maybe<Scalars['String']>;
  partner: Partner;
  /** Partner that will be off-boarded to the stall */
  partnerId: Scalars['ObjectId'];
  reasons: Array<OffboardingReason>;
};

export type StallOffboardingInput = {
  comment?: InputMaybe<Scalars['String']>;
  partnerId: Scalars['ObjectId'];
  reasons: Array<OffboardingReason>;
  scheduledAt: Scalars['DateTime'];
  status?: InputMaybe<StallScheduleStatus>;
};

export type StallOnboardingContent = ActionContentBase & {
  __typename?: 'StallOnboardingContent';
  actionType: ActionType;
  /**
   * Use this to document the change request.
   * For eg. user input text for Sales/Admin, jira tickets or slack discussions for migrations.
   */
  comment?: Maybe<Scalars['String']>;
  lease?: Maybe<Lease>;
  partner: Partner;
  /** Partner that will be onboarded to the stall */
  partnerId: Scalars['ObjectId'];
  stall: Stall;
};

export type StallOnboardingInput = {
  comment?: InputMaybe<Scalars['String']>;
  partnerId: Scalars['ObjectId'];
  scheduledAt: Scalars['DateTime'];
};

/** onboarding/offboarding schedule of stall */
export type StallSchedule = {
  __typename?: 'StallSchedule';
  offboarding?: Maybe<OffboardingDetails>;
  onboarding?: Maybe<OnboardingDetails>;
};

/** stall schedule details */
export type StallScheduleDetails = TimeStamps & {
  __typename?: 'StallScheduleDetails';
  _id: Scalars['ObjectId'];
  /** @deprecated use lease.brandAMs instead */
  brandAMs?: Maybe<Array<BrandAccountManager>>;
  /** Additional comment */
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** userId creating the schedule */
  createdBy: Scalars['ObjectId'];
  facilityId: Scalars['ObjectId'];
  /** @deprecated use lease.leadSigners instead */
  leadSigners?: Maybe<Array<User>>;
  /** Might not exist if onboarding not done from Sales */
  lease?: Maybe<Lease>;
  partner: Partner;
  /** Partner that will be onboarded/offboarded to the stall */
  partnerId: Scalars['ObjectId'];
  /** Reasons for offboarding */
  reasons?: Maybe<Array<OffboardingReason>>;
  /** Date when the stall will be onboarded/offboarded */
  scheduledAt: Scalars['DateTime'];
  stall: Stall;
  stallId: Scalars['ObjectId'];
  status?: Maybe<StallScheduleStatus>;
  tags: Array<StallScheduleTag>;
  type: EventType;
  updatedAt: Scalars['DateTime'];
  /** userId updating the schedule */
  updatedBy: Scalars['ObjectId'];
};

export type StallScheduleDetailsConnection = Connection & {
  __typename?: 'StallScheduleDetailsConnection';
  /** Use this if you want to do cursor based pagination, otherwise just use `nodes` directly. */
  edges: Array<StallScheduleDetailsEdge>;
  /** Use this directly if you don't want cursor based pagination, otherwise use `edges`. */
  nodes: Array<StallScheduleDetails>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type StallScheduleDetailsConnectionFilter = {
  countryIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  date?: InputMaybe<DateFilterInput>;
  facilityIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  facilityType?: InputMaybe<FacilityType>;
  occupancy?: InputMaybe<StallOccupancy>;
  partnerIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  search?: InputMaybe<Scalars['String']>;
  stallIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  stallNumber?: InputMaybe<Scalars['Int']>;
  /** Default is Active */
  stallStatuses?: InputMaybe<Array<StallStatus>>;
  /** Filter facilities by status */
  statuses?: InputMaybe<Array<FacilityStatus>>;
  tags?: InputMaybe<TagFilterWithOperator>;
  types?: InputMaybe<Array<StallType>>;
};

export type StallScheduleDetailsEdge = {
  __typename?: 'StallScheduleDetailsEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['Cursor'];
  node: StallScheduleDetails;
};

export type StallScheduleDiscardInput = {
  comment?: InputMaybe<Scalars['String']>;
  reasons?: InputMaybe<Array<OffboardingReason>>;
};

export type StallScheduleSort = {
  sortBy?: InputMaybe<StallScheduleSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

/** StallSchedule sort by options */
export enum StallScheduleSortBy {
  CreatedAt = 'CreatedAt',
  Id = 'Id',
  ScheduledAt = 'ScheduledAt',
  UpdatedAt = 'UpdatedAt'
}

export enum StallScheduleStatus {
  Draft = 'Draft',
  Scheduled = 'Scheduled'
}

export enum StallScheduleTag {
  Delayed = 'Delayed',
  New = 'New',
  OffBoarding = 'OffBoarding',
  OnBoarding = 'OnBoarding'
}

export enum StallStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type StallStatusAssignee = {
  __typename?: 'StallStatusAssignee';
  assignees: Array<User>;
  status: LeadStatus;
};

export enum StallType {
  Insulator = 'Insulator',
  Primary = 'Primary',
  Shared = 'Shared',
  Unassigned = 'Unassigned'
}

export type StallsFieldFilter = {
  partnerIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  stallIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Default is Active */
  stallStatuses?: InputMaybe<Array<StallStatus>>;
  types?: InputMaybe<Array<StallType>>;
};

export enum StatusView {
  AllCookingPackingReady = 'ALL_COOKING_PACKING_READY',
  AllCookingReady = 'ALL_COOKING_READY'
}

export type Subscription = {
  __typename?: 'Subscription';
  facilityUpdated: Facility;
  /** @deprecated No longer using cloud builds, replaced with app version check */
  getNewBuild: Scalars['String'];
  orderUpdates: Order;
  vendorMetricsUpdated: Array<VendorWithMetrics>;
};


export type SubscriptionFacilityUpdatedArgs = {
  facilityId: Scalars['ObjectId'];
};


export type SubscriptionOrderUpdatesArgs = {
  filter?: InputMaybe<OrderSubscriptionFilter>;
};


export type SubscriptionVendorMetricsUpdatedArgs = {
  facilityId: Scalars['ObjectId'];
};

export type TagFilterWithOperator = {
  and?: InputMaybe<Array<StallScheduleTag>>;
  or?: InputMaybe<Array<StallScheduleTag>>;
};

export type Tax = {
  __typename?: 'Tax';
  amount: Scalars['Float'];
  net_amount?: Maybe<Scalars['Float']>;
  tax_rate_percent?: Maybe<Scalars['Float']>;
};

export type TimeStamps = {
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Tokens = {
  __typename?: 'Tokens';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type TwoFactorSecretKey = {
  __typename?: 'TwoFactorSecretKey';
  ascii?: Maybe<Scalars['String']>;
  base32?: Maybe<Scalars['String']>;
  google_auth_qr?: Maybe<Scalars['String']>;
  hex?: Maybe<Scalars['String']>;
  otpauth_url?: Maybe<Scalars['String']>;
  qr_code_ascii?: Maybe<Scalars['String']>;
  qr_code_base32?: Maybe<Scalars['String']>;
  qr_code_hex?: Maybe<Scalars['String']>;
};

export type TwoFactorSecretKeyInput = {
  ascii?: InputMaybe<Scalars['String']>;
  base32?: InputMaybe<Scalars['String']>;
  google_auth_qr?: InputMaybe<Scalars['String']>;
  hex?: InputMaybe<Scalars['String']>;
  otpauth_url?: InputMaybe<Scalars['String']>;
  qr_code_ascii?: InputMaybe<Scalars['String']>;
  qr_code_base32?: InputMaybe<Scalars['String']>;
  qr_code_hex?: InputMaybe<Scalars['String']>;
};

/** Update Brand input */
export type UpdateBrandInput = {
  assigneeId?: InputMaybe<Scalars['ObjectId']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  partnerId?: InputMaybe<Scalars['ObjectId']>;
  /** New Partner is created with this name and assigned to this brand */
  partnerName?: InputMaybe<Scalars['String']>;
};

/** Update facility input */
export type UpdateFacilityInput = {
  location?: InputMaybe<FacilityLocationInput>;
  name?: InputMaybe<Scalars['String']>;
  numberOfStalls?: InputMaybe<Scalars['Int']>;
  ordersManagement?: InputMaybe<OrderManagementInput>;
  status?: InputMaybe<FacilityStatus>;
  timezone?: InputMaybe<Scalars['String']>;
};

/** LeadId and lease info to update */
export type UpdateLeadInput = {
  leadId: Scalars['ObjectId'];
  lease?: InputMaybe<LeaseInput>;
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<LeadStatus>;
};

/** Data for updating status of leads of a single brand */
export type UpdateLeadsStatusInput = {
  brandId: Scalars['ObjectId'];
  leadUpdates: Array<UpdateLeadInput>;
  note?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['ObjectId']>;
  /** New Partner is created with this name and assigned to this brand */
  partnerName?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<LeadStatus>;
};

export type UpdatePartnerInput = {
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PartnerStatus>;
};

export type UpdatePasswordUserInput = {
  facilityId?: InputMaybe<Scalars['ObjectId']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['ObjectId']>;
  role?: InputMaybe<UserRole>;
};

export type UpdateStallInput = {
  cookingAppSettings?: InputMaybe<CookingAppDisplaySettingsInput>;
  data?: InputMaybe<StallDataInput>;
  partnerId?: InputMaybe<Scalars['ObjectId']>;
  stations?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateUserInput = {
  _id?: InputMaybe<Scalars['ID']>;
  countries?: InputMaybe<Array<Scalars['ID']>>;
  firstName?: InputMaybe<Scalars['String']>;
  kitchen?: InputMaybe<Scalars['ID']>;
  lastName?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Roles>>;
};

export type UpdateVendorInput = {
  label: Scalars['String'];
  settings?: InputMaybe<VendorSettingsInput>;
};

/** The User model */
export type User = TimeStamps & {
  __typename?: 'User';
  _id: Scalars['ID'];
  access: Access;
  /** @deprecated user no longer has countries */
  countries: Array<Country>;
  /** @deprecated user no longer has a single country */
  country: Country;
  /** @deprecated user no longer has countries */
  countryIds?: Maybe<Array<Scalars['ObjectId']>>;
  createdAt: Scalars['DateTime'];
  deactivated?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  emails: Array<EmailRecord>;
  /** @deprecated user no longer has a single facility */
  facility: Facility;
  /** @deprecated user no longer has a single facility */
  facilityId: Scalars['ObjectId'];
  /** @deprecated user no longer has a single facility type */
  facilityType: FacilityType;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  /** @deprecated use access instead */
  permissions: Array<Scalars['String']>;
  /** @deprecated use access instead */
  role: Roles;
  /** @deprecated not used */
  roles: Array<Roles>;
  updatedAt: Scalars['DateTime'];
  username?: Maybe<Scalars['String']>;
};

export type UserConnection = Connection & {
  __typename?: 'UserConnection';
  /** Use this if you want to do cursor based pagination, otherwise just use `nodes` directly. */
  edges: Array<UserEdge>;
  /** Use this directly if you don't want cursor based pagination, otherwise use `edges`. */
  nodes: Array<User>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['Cursor'];
  node: User;
};

export type UserInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  username?: InputMaybe<Scalars['String']>;
};

export enum UserRole {
  BusinessDeveloper = 'BusinessDeveloper',
  BusinessDevelopmentManager = 'BusinessDevelopmentManager',
  CookingStaff = 'CookingStaff',
  CountryManager = 'CountryManager',
  Dashboard = 'Dashboard',
  FacilityManager = 'FacilityManager',
  OperationsManager = 'OperationsManager',
  Operator = 'Operator',
  Partner = 'Partner',
  Picker = 'Picker',
  PlatformAccountManager = 'PlatformAccountManager',
  ReadOnly = 'ReadOnly',
  Runner = 'Runner',
  SuperUser = 'SuperUser'
}

export type UserSort = {
  sortBy?: InputMaybe<UserSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

/** User sort by options */
export enum UserSortBy {
  CreatedAt = 'CreatedAt',
  Email = 'Email',
  Id = 'Id',
  Name = 'Name',
  UpdatedAt = 'UpdatedAt'
}

export type UsersFilter = {
  countryId?: InputMaybe<Scalars['ObjectId']>;
  countryIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  deactivated?: InputMaybe<Scalars['Boolean']>;
  facilityIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  facilityType?: InputMaybe<FacilityType>;
  newRole?: InputMaybe<UserRole>;
  partnerIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  role?: InputMaybe<Roles>;
  roles?: InputMaybe<Array<Roles>>;
  search?: InputMaybe<Scalars['String']>;
  userRoles?: InputMaybe<Array<UserRole>>;
};

export type VendorConnection = Connection & {
  __typename?: 'VendorConnection';
  /** Use this if you want to do cursor based pagination, otherwise just use `nodes` directly. */
  edges: Array<VendorEdge>;
  /** Use this directly if you don't want cursor based pagination, otherwise use `edges`. */
  nodes: Array<VendorModule>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type VendorConnectionFilter = {
  countryIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  facilityIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  facilityType?: InputMaybe<FacilityType>;
  partnerIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  search?: InputMaybe<Scalars['String']>;
  /** Takes priority over facilityIds */
  stallIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  status?: InputMaybe<VendorStatus>;
};

export type VendorDelayStats = {
  __typename?: 'VendorDelayStats';
  sevenDaysAggregatedStats: Array<SevenDaysAggregatedStats>;
  todayStats: Array<VendorStats>;
};

export type VendorEdge = {
  __typename?: 'VendorEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['Cursor'];
  node: VendorModule;
};

export type VendorFilter = {
  /** Platform + country */
  globalEntityId: Scalars['ID'];
  vendorId: Scalars['ID'];
};

export type VendorFilterObject = {
  __typename?: 'VendorFilterObject';
  /** Platform + country */
  globalEntityId: Scalars['ID'];
  vendorId: Scalars['ID'];
};

export type VendorInfo = {
  __typename?: 'VendorInfo';
  charges?: Maybe<Charges>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type VendorMetrics = {
  __typename?: 'VendorMetrics';
  deliveredOrdersCount: Scalars['Float'];
  ordersCount: Scalars['Float'];
  staffCount: Scalars['Float'];
};

export type VendorModule = TimeStamps & {
  __typename?: 'VendorModule';
  /** Is the vendor online/offline on the Platform */
  available?: Maybe<Scalars['Boolean']>;
  /** chain id of the vendor. By default - chain_id from DataStreams */
  chainId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** DataStreams Vendor API Response */
  details?: Maybe<Scalars['JSONObject']>;
  events: Array<Event>;
  facility: Facility;
  facilityId: Scalars['ObjectId'];
  facilityType: FacilityType;
  /** Platform + country */
  globalEntityId: Scalars['ID'];
  /** Go live date of vendor in the facility. By default - activation_date from DataStreams */
  goLiveDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  orders: OrderConnection;
  partner: Partner;
  partnerId: Scalars['ObjectId'];
  settings: VendorSettings;
  stallIds: Array<Scalars['ObjectId']>;
  stalls: Array<Stall>;
  status: VendorStatus;
  updatedAt: Scalars['DateTime'];
  vendorId: Scalars['ID'];
};


export type VendorModuleOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<OrderFieldFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<OrderSort>;
};

export type VendorSettings = {
  __typename?: 'VendorSettings';
  defaultPrepTime: Scalars['Float'];
  orderStatusChange: OrderStatusChange;
  pickupTimeThreshold?: Maybe<Scalars['Float']>;
  preparationTimeThreshold?: Maybe<Scalars['Float']>;
  window?: Maybe<Scalars['String']>;
};

export type VendorSettingsInput = {
  defaultPrepTime: Scalars['Float'];
  orderStatusChange: OrderStatusChange;
  pickupTimeThreshold?: InputMaybe<Scalars['Float']>;
  preparationTimeThreshold?: InputMaybe<Scalars['Float']>;
  window?: InputMaybe<Scalars['String']>;
};

export type VendorStats = {
  __typename?: 'VendorStats';
  geidVid: Scalars['ID'];
  stats: VendorStatsMetrics;
};

export type VendorStatsMetrics = {
  __typename?: 'VendorStatsMetrics';
  /** Orders ready count (with status in history: ReadyForPickup or PickedUp or Delivered) */
  readyCount?: Maybe<Scalars['Float']>;
  /** vendor's order count that were delayed for more than 10 minutes */
  vendorDelayCount10m?: Maybe<Scalars['Float']>;
};

export enum VendorStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type VendorWithMetrics = TimeStamps & {
  __typename?: 'VendorWithMetrics';
  /** Is the vendor online/offline on the Platform */
  available?: Maybe<Scalars['Boolean']>;
  /** chain id of the vendor. By default - chain_id from DataStreams */
  chainId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** DataStreams Vendor API Response */
  details?: Maybe<Scalars['JSONObject']>;
  events: Array<Event>;
  facility: Facility;
  facilityId: Scalars['ObjectId'];
  facilityType: FacilityType;
  /** Platform + country */
  globalEntityId: Scalars['ID'];
  /** Go live date of vendor in the facility. By default - activation_date from DataStreams */
  goLiveDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  metrics: VendorMetrics;
  orders: OrderConnection;
  partner: Partner;
  partnerId: Scalars['ObjectId'];
  settings: VendorSettings;
  stallIds: Array<Scalars['ObjectId']>;
  stalls: Array<Stall>;
  status: VendorStatus;
  updatedAt: Scalars['DateTime'];
  vendorId: Scalars['ID'];
};


export type VendorWithMetricsOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<OrderFieldFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<OrderSort>;
};
